import { initializeApp } from "firebase/app";
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore"
import { getStorage, connectStorageEmulator } from "firebase/storage"
import { getAuth, connectAuthEmulator, GoogleAuthProvider } from "firebase/auth"
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";


// Firebase web config
const firebaseConfig = {
  apiKey: process.env.FIREBASE_apiKey,
  authDomain: process.env.FIREBASE_authDomain,
  projectId: process.env.FIREBASE_projectId,
  storageBucket: process.env.FIREBASE_storageBucket,
  messagingSenderId: process.env.FIREBASE_messagingSenderId,
  appId: process.env.FIREBASE_appId
};

class Firebase {

  constructor() {
    
    if(typeof window !== "undefined") {
      this.app = initializeApp(firebaseConfig);
      this.auth = getAuth(this.app);

      this.uiConfig = {
        signInFlow: 'popup',
        signInSuccessUrl: '/escritorio/mi-biblioteca',
        signInOptions: [ GoogleAuthProvider.PROVIDER_ID ],
      }
    }
  }
  
}

export default Firebase;
